import React, { useEffect, useState, type ReactNode } from "react";
import { Popover, PopoverTrigger, PopoverContent } from "@nextui-org/popover";

interface Props {
  trigger?: ReactNode;
  content?: ReactNode;
};

const Regions:React.FC<Props> = ({trigger, content}) => {
  const [ isOpen, setIsOpen ] = useState(false);
  useEffect(() => {
    document.body.addEventListener("scroll", () => isOpen && setIsOpen(false));
  });

  return (
    <Popover
      classNames={{
        content: [
          "bg-white text-slate-900 border shadow-y-md py-5 transition-opacity items-stretch justify-normal",
          "transition-all duration-300",
          "sm:mt-3 sm:inset-x-0 sm:top-full sm:right-auto sm:bottom-auto sm:rounded-xl",
          "md:mt-4 lg:mt-5 min-w-[calc(100vw-32px)] md:min-w-max"
        ],
      }}
      // shouldBlockScroll={true}
      placement="bottom-start"
      shadow="md"
      isOpen={isOpen}
      onOpenChange={(open) => setIsOpen(open)}
      shouldCloseOnBlur={true}
      data-open={isOpen?"":undefined}
      style={{
        position: "fixed",
        maxHeight: "initial",
      }}
    >
      <PopoverTrigger><div className="group/trigger">{trigger}</div></PopoverTrigger>
      <PopoverContent className="overflow-y-auto max-h-[calc(100vh-58px)]" aria-label="Regions List">{content}</PopoverContent>
    </Popover>
  );
}

export default Regions;